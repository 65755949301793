
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class StripeConnectFinishedComponent extends Vue {
  /**
   * closes the modal
   */
  private dismiss() {
    this.$emit('closeDialog');
  }
}
